/** @format */

import React from "react";
import { Link } from "react-router-dom";
import { FaXTwitter, FaTelegram } from "react-icons/fa6";

const Footer = () => {
  return (
    <footer className='footer p-10 bg-[#00014E] text-neutral-content'>
      <aside>
        <Link to='/' className='btn btn-ghost text-3xl text-white'>
          <img src='assets/logo.png' className='img-fluid ' width={40} alt='' />
          Meta Meme
        </Link>
        <p>© 2023. All Rights Reserved.</p>
      </aside>
      <nav>
        <header className='footer-title'>Social</header>
        <div className='grid grid-flow-col gap-4'>
          <a
            href='https://twitter.com/MetaMemeERC_20'
            target='_blank'
            rel='noreferrer'>
            <FaXTwitter className='text-3xl' />
          </a>
          <a
            target='_blank'
            rel='noreferrer'
            href='https://t.me/MetaMemeERC_20'>
            <FaTelegram className='text-3xl' />
          </a>
        </div>
      </nav>
    </footer>
  );
};

export default Footer;
