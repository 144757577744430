/** @format */

import React from "react";

const Tweet = () => {
  return (
    <div className='w-4/4 sm:w-3/4 m-auto my-5'>
      <div className='mockup-window border border-base-300'>
        <div className='flex justify-center px-4 py-16 border-t border-base-300'>
          <img src='assets/tweet.jpeg' alt='' />
        </div>
      </div>
    </div>
  );
};

export default Tweet;
