/** @format */

import React from "react";
import Hero from "../components/elements/Hero";
import About from "../components/elements/About";
import Tweet from "../components/elements/Tweet";
import Tokenomics from "../components/elements/Tokenomics";

const Home = () => {
  return (
    <>
      <Hero />
      <About />
      <Tweet />
      <Tokenomics />
    </>
  );
};

export default Home;
