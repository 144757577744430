/** @format */

import React from "react";

const Tokenomics = () => {
  return (
    <div className='min-w-screen  flex items-center flex-col justify-center px-5 py-5 my-5'>
      <h2 className='mb-6 max-w-lg text-3xl font-light my-5 text-center leading-snug tracking-tight text-g1 sm:text4xl sm:leading-snug'>
        <span className='my-1  border-b-8 border-g4 bg-white px-4 font-bold text-g4 animate__animated animate__flash'>
          <q>Tokenomics</q>
        </span>
      </h2>
      <div className='w-full max-w-3xl mt-10 '>
        <div className='-mx-2 md:flex'>
          <div className='w-full md:w-1/3 px-2'>
            <div className='rounded-lg shadow-sm mb-4'>
              <div className='rounded-lg py-4 bg-white shadow-lg md:shadow-xl relative overflow-hidden border-2 border-blue-950'>
                <div className='px-3 pt-8 pb-10 text-center relative z-10'>
                  <h4 className='text-sm uppercase text-gray-500 leading-tight'>
                    Buy
                  </h4>
                  <h3 className='text-3xl text-gray-700 font-semibold leading-tight my-3'>
                    0%
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className='w-full md:w-2/3 px-2'>
            <div className='rounded-lg shadow-sm mb-4'>
              <div className='rounded-lg py-4 bg-white shadow-lg md:shadow-xl relative overflow-hidden  border-2 border-blue-950'>
                <div className='px-3 pt-8 pb-10 text-center relative z-10'>
                  <h4 className='text-sm uppercase text-gray-500 leading-tight'>
                    Total Supply
                  </h4>
                  <h3 className='text-3xl text-gray-700 font-semibold leading-tight my-3'>
                  690,000,000,000
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className='w-full md:w-1/3 px-2'>
            <div className='rounded-lg shadow-sm mb-4'>
              <div className='rounded-lg py-4 p bg-white shadow-lg md:shadow-xl relative overflow-hidden  border-2 border-blue-950'>
                <div className='px-3 pt-8 pb-10 text-center relative z-10'>
                  <h4 className='text-sm uppercase text-gray-500 leading-tight'>
                    Sell
                  </h4>
                  <h3 className='text-3xl text-gray-700 font-semibold leading-tight my-3'>
                    0%
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tokenomics;
