/** @format */

import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <header>
      <div className='navbar bg-[#00014E] '>
        <div className='navbar-start '>
          <div className='dropdown'>
            <label tabIndex={0} className='btn btn-ghost lg:hidden'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-5 w-5'
                fill='none'
                viewBox='0 0 24 24'
                stroke='#fff'>
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M4 6h16M4 12h8m-8 6h16'
                />
              </svg>
            </label>
            <ul
              tabIndex={0}
              className='menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow text-white rounded-box w-52 bg-[#00014E]'>
              <li>
                <a>Home</a>
              </li>
              <li>
                <a
                  href='https://app.uniswap.org/swap?outputCurrency=0x5ee71023e77f885d389d597d42ae1390cd0571a4'
                  target='_blank'
                  rel='noreferrer'>
                  Uniswap
                </a>
              </li>
              <li>
                <a
                  href='https://www.dextools.io/app/en/ether/pair-explorer/0x32f9f8269d6ffc60b45489e69868b7173d34d6d2'
                  target='_blank'
                  rel='noreferrer'>
                  Dextool
                </a>
              </li>
              <li>
                <a
                  href='https://etherscan.io/address/0x5ee71023e77f885d389d597d42ae1390cd0571a4'
                  target='_blank'
                  rel='noreferrer'>
                  Contract
                </a>
              </li>
            </ul>
          </div>
          <Link to='/' className='btn btn-ghost text-3xl text-white'>
            <img
              src='assets/logo.png'
              className='img-fluid '
              width={40}
              alt=''
            />
            Meta Meme
          </Link>
        </div>
        <div className='navbar-center hidden lg:flex'>
          <ul className='menu menu-horizontal px-1 text-white'>
            <li>
              <a>Home</a>
            </li>
            <li>
              <a
                href='https://app.uniswap.org/swap?outputCurrency=0x5ee71023e77f885d389d597d42ae1390cd0571a4'
                target='_blank'
                rel='noreferrer'>
                Uniswap
              </a>
            </li>
            <li>
              <a
                href='https://www.dextools.io/app/en/ether/pair-explorer/0x32f9f8269d6ffc60b45489e69868b7173d34d6d2'
                target='_blank'
                rel='noreferrer'>
                Dextool
              </a>
            </li>
            <li>
              <a
                href='https://etherscan.io/address/0x5ee71023e77f885d389d597d42ae1390cd0571a4'
                target='_blank'
                rel='noreferrer'>
                Contract
              </a>
            </li>
          </ul>
        </div>
        <div className='navbar-end'>
          <a
            target='_blank'
            rel='noreferrer'
            className='btn bg-[#01FFDB] border-0 rounded-sm hover:bg-[#01FFDBca]'
            href='https://t.me/MetaMemeERC_20'>
            Join Telegram
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
