/** @format */

import React from "react";

const Hero = () => {
  return (
    <div
      className='hero min-h-screen'
      style={{
        backgroundImage: "url(assets/banner.png)",
      }}>
      <div className='hero-overlay bg-opacity-10'></div>
      <div className='hero-content text-center text-neutral-content backdrop-blur-md'>
        <div className='max-w-md'>
          <h1 className='mb-5 text-5xl text-black font-bold '>
            &#123;Meta Meme&#125;
          </h1>
          <p className='mb-5 text-black '>
            Join the journey to achieve a 1 $billion market cap by joining the
            Meta Meme Coin
          </p>
          <a
            href='https://app.uniswap.org/swap?outputCurrency=0x5ee71023e77f885d389d597d42ae1390cd0571a4'
            target='_blank'
            rel='noopener noreferrer'>
            {" "}
            <button className=' bg-[#01FFDB] border-2 shadow-md border-gray-950 rounded-sm mx-3  px-6 py-2.5 text-black font-bold hover:bg-[#01FFDBca]'>
              Buy Now
            </button>
          </a>
          <a
            href='https://www.dextools.io/app/en/ether/pair-explorer/0x32f9f8269d6ffc60b45489e69868b7173d34d6d2'
            target='_blank'
            rel='noopener noreferrer'>
            {" "}
            <button className=' bg-[#01FFDB] border-2 shadow-md border-gray-950 rounded-sm   px-6 py-2.5 text-black font-bold hover:bg-[#01FFDBca]'>
              Chart
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Hero;
